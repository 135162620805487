body.page-template-template-account {
  .woocommerce-MyAccount-navigation {
    li {
      position: relative;
      @apply bg-zz-beige;
      margin-bottom: 3px;
      padding: 20px 20px 20px 0;

      a {
        @apply text-zz-black;
        font-size: 20px;
        line-height: 1.2;
        font-weight: 900;
        font-family: $secondary-font;
        text-transform: uppercase;
      }

      &.is-active {
        a {
          @apply text-zz-white;
        }
      }

      &:before {
        position: absolute;
        content: '';
        width: 50vw;
        height: 100%;
        right: 100%;
        top: 0;
        @apply bg-zz-beige;
      }
    }
  }

  form {
    .form-row {
      label {
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 8px;
        padding: 0;
        line-height: 1.2;
      }

      input,textarea, select {
        padding:8px;
        outline: none !important;
        border: 2px solid;
        border-radius: 4px;
        background-color: white;

        @apply border-zz-grey;

        &:active, &:focus {
          @apply border-zz-blue;
        }
      }
    }

    [type="submit"] {
      margin-top: 30px;
      border-radius: 4px;
      border: 2px solid;

      @apply bg-zz-blue text-white font-bold py-4 px-8 border-zz-blue transition;
      @apply hover:bg-zz-white hover:text-zz-blue;
    }

    .required {
      color: rgb(105, 172, 223) !important;
    }
  }

  h2 {
    font-size: 25px;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .woocommerce-order-details, .woocommerce-Addresses {
    margin-top: 2em;
  }

  mark {
    @apply bg-zz-blue text-white;
  }
}