@import '../variables';

.archive-behandelaars {
  #behandelaars-map {
    width:100%;
    height: 700px;
  }

  .search-form {
    @apply bg-zz-beige;
    margin-bottom: 15px;
    padding: 10px;

    display: flex;
    justify-content: space-between;

    #search-address {
      font-size: 18px;
      line-height: 22px;

      outline: none !important;
      padding: 5px;

      &::placeholder {
        color: black;
      }
    }

    #search-radius {
      font-size: 18px;
      line-height: 22px;
      outline: none !important;
      padding: 4px;
      text-align: center;
      color: black;
    }
  }

  .finder-section {
    .content {
      @apply grid lg:grid-cols-3 gap-12;

      .main-content {
        @apply lg:col-span-2;
      }

    }
  }

  .sidebar {

    .sidebar-controls {
      margin-top: 30px;

      button {
        @apply text-zz-blue;
        font-size: 25px;
        line-height: 30px;
      }

      .behandelaars-list-counter {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        padding: 0 15px;
      }
    }
  }

  .behandelaars-sidebar {

    .behandelaar-item {
      position: relative;
      cursor: pointer;

      &:first-of-type {
        border-top: 3px solid #F0EEEE;
      }
      border-bottom: 3px solid #F0EEEE;

      padding: 25px 0;

      &[aria-hidden="false"] {
        display: block;
      }

      &[aria-hidden="true"] {
        display: none;
      }

      .title {
        @apply text-zz-blue;
        @include secondary-font();
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 15px;
      }

      .info {
        @apply grid grid-cols-2;
      }
    }

  }
}