.template-contact {
  .content {
    .inner {
      @apply grid md:grid-cols-2 gap-36;

      h2 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 30px;
      }
    }
  }

  .company-info {
    position: relative;
    z-index: 1;

   .company-content {
     position: relative;
     @apply bg-zz-beige;
     padding: 35px 45px;

     ul {
       margin-bottom: 30px;

       li {
         a {
           @apply text-black hover:text-zz-blue transition;
         }
       }
     }
     &:after {
       z-index: -1;
       content: "";
       position: absolute;
       top:0;
       left:0;
       width: 50vw;
       height: 100%;

       @apply bg-zz-beige;
     }
   }
  }

  #map {
    height: 300px;
    width: 100%;
  }
}

