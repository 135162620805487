.ff-stream {
  background-color: transparent !important;
}

.ff-loadmore-wrapper {
  display: none !important;
}

.ff-item-cont {
  .ff-img-holder {
    img {
      height: 260px;
      width: 100%;
      object-fit:cover;
      min-height: unset !important;
    }
  }
  .ff-item-meta {
    text-align: right;
    margin-bottom: 0;

    h6 {
      display: none !important;
    }

    .ff-userpic {
      border-radius: 50% !important;
      margin: unset !important;
    }
    .ff-timestamp {
      display: none !important;
    }
    .ff-nickname {
      margin-top: -60px;

      max-width: unset !important;
      overflow: visible !important;
      text-overflow: unset !important;
      font-weight: 700;

      &:before {
        content:'@';
      }
      &:after {
        display: none !important;
      }
    }
  }

  .ff-content {
    margin-top: 0;
  }

  .ff-label-wrapper {
    line-height: 0;
    top: -5px !important;
  }
}