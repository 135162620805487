@import '../../_variables.scss';

footer.main-footer {
  .footer-top {
    pointer-events: none;
  }

  .footer-content {
    // set background image
    //background-image: url("../images/footer-bottom.svg");
    // repeat vertically
    background-repeat: repeat-y;
    // center vertically
    background-position: top center;
    // stretch to fill
    background-size: 100% auto;

    background-color: #9ec9eb;

    .inner {
      position: relative;
      z-index: 1;

      @apply grid sm:grid-cols-2 lg:grid-cols-4;
      @apply gap-12;

      @apply py-20;

      .logo-col {
        img {
          min-width: 164px;
        }
      }

      .info-col {
        ul {
          li{
            color: black;
            font-size: 15px;
            line-height: 21px;
            a {
              @apply transition text-black hover:text-white;

            }
          }

          .title {
            @include secondary-font();
            color: white;
            font-size: 15px;
            line-height: 21px;
            text-transform: uppercase;
          }
        }
      }

      .social-col {
        .title {
          @include secondary-font();
          color: white;
          font-size: 15px;
          line-height: 21px;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        .icons {
          a {
            @apply text-white hover:text-zz-blue transition;

            font-size: 25px;
            margin-right: 15px;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}