$headers: h1,h2,h3,h4,h5,h6;

@mixin headers($start_from: h1) {
  $headersToBeIncludes:$start_from;
  $passed_start: false;

  @each $header in $headers {
    @if $header == $start_from {
      $passed_start: true;
    }

    @if $passed_start and $header != $start_from {
        $headersToBeIncludes: $headersToBeIncludes + ', ' + $header;
    }
  }

  #{$headersToBeIncludes} {
    @content;
  }
}