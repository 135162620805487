$white: #fff;
$black: #000;
$beige: #E5DDD2;
$blue: #69ACDF;

$spacing: 100px;

@import 'font';


@mixin Button() {

}

@mixin DropdownHeader($backgroundColor, $borderColor, $textColor) {
  .menu-item-has-children  {
    > a {
      &:after {
        margin-left: 10px;
        content: '\f078';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 500;
        font-size: 12px;
      }
    }

    display: inline-block;
    position: relative;

    &:hover {
      .sub-menu {
        transition: all .2s ease-in-out;
        visibility: visible;
        opacity: 1;
        pointer-events: all;
      }
    }

    .sub-menu {
      pointer-events: all;
      visibility: hidden;
      opacity: 0;
      z-index: 1111;
      position: absolute;

      bottom: 0;
      left: 0;
      transform: translateY(100%);

      height: auto;
      transition: all .2s ease-in-out;
      display: block !important;
      padding: 15px;

      text-align: left;
      background-color: $backgroundColor;
      border: 1px solid $borderColor;

      a {
        color: $textColor;
        padding: 5px;
        text-transform: none;
      }

      li, li a {
        display: block !important;
        border: none !important;
      }
    }
  }
}