.template-faq {
}
.faq-item {
  border-bottom: 1px solid #eaeaea;

  .faq-content {
    display: none;

    background-color: #FAF8F6;

    padding: 12px 50px;
  }

  .faq-header {
    background-color: white;
    cursor: pointer;

    transition: all .2s;
    * {
      transition: all .2s;
    }

    @apply flex justify-between;
    padding: 25px 50px;

    span {
      font-size: 20px;
      line-height: 28px;
      @apply text-black;
    }
    i{
      transition: all .2s;
      font-size: 25px;
      line-height: 28px;
      @apply text-zz-blue;
    }
  }

  &[aria-expanded="true"], &:hover {
    .faq-header {
      @apply bg-zz-beige;

      span {
        text-shadow: 1px 0 0 black;
      }

      i {
        transform: rotate(180deg);
        @apply text-white;
      }
    }
  }

  &[aria-expanded="true"] .faq-content {
    display: block;
  }
}