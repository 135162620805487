.mobile_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99999;
  display: none;

  &[aria-expanded="true"] {
    display: block;
  }

  .menu {
    margin: 15px 0;

    li {
      margin-bottom: 15px;
      a {
        @include secondary-font();
        color: black;
        font-size: 2.6rem;
        line-height: 1.2;
        margin-bottom: 1.5rem;
        text-decoration: none !important;

        &:focus, &:active {
          @apply text-zz-blue;
        }
      }

      &.current_page_item {
        a {
          @apply text-zz-blue;
        }
      }

      &.menu-item-has-children {
        > a {
          display: flex;
          justify-content: space-between;
        }

        .sub-menu {
          display: none;
          li {
            margin-bottom: 10px;
            a {
              font-size: .9em;
              font-weight: 400;
            }
          }

          &[aria-expanded="true"] {
            display: block;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;

    .btn-icon {
      color: white;
    }
  }
}

.mobile_menu_trigger {
  i {
    font-size: 2.5rem;
    @apply text-zz-blue;
  }

  .on-closed {
    display: block;
  }
  .on-open {
    display: none;
  }

  &.open {
    .on-closed {
      display: none;
    }
    .on-open {
      display: block;
    }
  }
}