@import '../variables';

.single-product {
  h2 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 15px;
  }

  .woocommerce-tabs {
    z-index: 1;
    position: relative;

    margin-top: $spacing;
    margin-bottom: $spacing;
    display: inline-block;

    .tabs {
      display: none;
    }

    .woocommerce-Tabs-panel {
      p {
        font-size: 18px;
        line-height: 25px;
        max-width: 1200px;
      }
    }
  }

  .woocommerce-product-gallery {
    z-index: 1;
  }

  main {
    .woocommerce-product-gallery__image {
      min-height: 500px;
    }

    .woocommerce-product-gallery__image a img {
      max-height: 500px;
      object-fit: contain;
    }

    .summary {
      padding: 60px;
      margin: 0;

      .woocommerce-product-details__short-description {
        p {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .product_meta {
        margin-bottom: 35px;
      }

      .price {
        font-size: 22px;
        line-height: 31px;
        margin-bottom: 15px;
        display: inline-block;
        color: black !important;
      }

      @apply bg-zz-beige;

      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        @apply bg-zz-beige;

        z-index: -1;
      }

      h1 {
        font-size: 25px;
        line-height: 35px;
      }

      .variations {
        margin-top: 25px;
        margin-bottom: 0 !important;

        tr {
          .label {
            display: none;
          }

          .variable-items-wrapper {
            padding: 25px 0;
            border-top: 2px solid;
            border-bottom: 2px solid;
            border-color: #EEEEEE;

            li {
              box-shadow: none !important;
              @apply text-zz-black;
              &[aria-checked='true'] {
                @apply bg-zz-blue text-white;

                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2) !important;
              }
            }
          }
        }
      }
    }
  }

  .woocommerce-variation-add-to-cart {
    .quantity {
      margin: 0 !important;

      input[type='number'] {
        outline: none !important;
        display: inline-block;
        text-decoration: none !important;
        font-size: 20px;
        width: 80px;
        line-height: 28px;
        border-radius: 8px 0 0 8px;
        font-family: $primary-font;
        border: 3px solid;
        padding: 5px 0;
        border-right: none;

        @apply transition border-zz-blue;
      }
    }

    button[type="submit"] {

      display: inline-block;
      text-decoration: none !important;
      font-size: 20px;
      line-height: 28px;
      border-radius: 0 8px 8px 0;
      font-family: $primary-font;
      border: 3px solid;
      padding: 5px 20px;


      @apply transition border-zz-blue bg-zz-blue text-white;
    }

    &:hover {
      button[type="submit"] {
        @apply bg-zz-white text-zz-blue;
      }
    }

    &.woocommerce-variation-add-to-cart-disabled {
      .quantity {
        input[type='number'] {
          border-color: #eeeeee;
        }
      }

      button[type="submit"] {
        border-color: #eeeeee;
        background-color: #EEEEEE;
        color: black;
      }
    }
  }
}