.woocommerce-products-header {
  .description-container {
    display: flex;
    justify-content: center;

    .page-description {
      max-width: 950px;
      @apply text-center;
    }
  }
}


@mixin WoofStyling() {
  .woof_container {
    margin: 0 0 3px 0;
    padding: 20px 20px 20px 0;
    position: relative;
    z-index: 1;
    @apply bg-zz-beige;

    &:before {
      right: 100%;
      width: 50vw;
      height: 100%;
      content: "";
      position: absolute;
      top: 0;
      @apply bg-zz-beige;
    }

    .woof_container_inner {
      h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .woof_list {
        li {
          display: flex;
          align-items: center;

          a {
            padding: 0 0 0 7px;
          }
        }
      }
    }
  }
}

.main-shop-container {
  .layout-container {
    @apply grid lg:grid-cols-4 gap-12;

    .side-filter {
      .product-count {
        z-index: 1;
        position: relative;
        margin-bottom: 15px;
      }

      h1,h2,h3,h4 {
        font-size:20px;
        line-height:1.2;
      }

      .widgettitle {
        display: none;
      }

      @include WoofStyling;
    }

    .shop-content {
      @apply lg:col-span-3;

    }
  }
}

.products {

  .product {
    .woocommerce-loop-product__title {
      padding: 0;
      font-size: 20px;
      line-height: 28px;
    }

    .price {
      color: black !important;
    }

    .other-product-content {
      @apply flex gap-4 justify-center items-center;

      .btn {
        padding: 5px;
      }
    }
  }
}