@import '../../_variables.scss';

header.main-header {
  position: sticky;
  top: 0;
  z-index: 199999;
  background-color: transparent;
  transition: box-shadow 0.3s ease-in-out, background-color 0.1s ease-in-out;

  &[data-has-scrolled="true"] {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }


  .items-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      .logo {
        max-height: 88px;
      }
      a{
        img{
          height: 88px;
        }
      }
    }

    .mobile-right {
      @apply flex lg:hidden;
    }

    .right {
      @apply hidden lg:flex;
      align-items: center;

      .menu {
        display: flex;

        li {
          a {
            @include secondary-font();
            @apply text-[14px] xl:text-[15px];
            line-height: 21px;
            color: black;
            margin-left: 18px;
            text-transform: uppercase;
          }

          &.current_page_item {
            > a {
              @apply text-zz-blue;
            }
          }
          &.menu-item-type-custom:hover{
            a{
              text-decoration: none;
              cursor: default;
            }
          }
        }

        .menu-item-has-children {
          position: relative;

          .sub-menu {
            position: absolute;
            bottom: 0;
            left: 50%;
            background-color: white;
            display: none;

            width: max-content;

            transform: translate(-50%, 100%);

            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);

            border-radius: 0 0 6px 6px;

            li {
              padding: 10px 15px;

              a {
                font-weight: 400;
              }

              &.current-menu-item {
                a {
                  @apply text-zz-blue;
                }
              }
              &:hover{
                a{
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
            }
          }

          &:hover {
            .sub-menu {
              display: block;
            }
          }
        }
      }

      .buttons {
        a {
          margin-left: 18px;
        }
      }

      &.smaller-right {
        font-size: 14px;
      }
    }
  }
}

.faq-item {
  ul {
    list-style: disc;
    margin-left: 1em;
  }
}