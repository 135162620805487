.template-vip-area {
  .links {
    @apply grid md:grid-cols-2 gap-12;

    .link {
      height: 100%;
      position: relative;

      .content {
        height: 100%;
        padding: 20px;
        border: 1px solid;
        border-radius: 7px;
        @apply border-zz-blue flex items-center gap-8;

        .left {
          i {
            font-size: 40px;
            line-height: 1;
            @apply text-zz-blue;
          }
        }

        .right {
          h2 {
            font-size: 25px;
            line-height: 35px;
            margin-bottom: 7px;
          }

          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }

      .stretched-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:hover {
        @apply bg-zz-blue;
        border-radius: 7px;

        .left {
          i {
            @apply text-white;
          }
        }

        .right {
          h2 {
            @apply text-white;
          }
        }
      }
    }
  }
}