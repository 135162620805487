.cart_popup {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 11;

  .inner {
    @apply bg-zz-blue shadow-2xl text-white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    display: flex;

    font-size: 22px;
    line-height: 1.2;

    justify-content: center;
    align-items: center;

    .count {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 14px;
      min-height: 25px;
      min-width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      @apply bg-white text-zz-blue shadow-lg transition-all;
      border-radius: 5000px;
      transform: translate(-50%, -50%);
    }

    .link {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &:hover {
      .count {
        @apply text-white bg-zz-blue;
      }
    }
  }
}