@import 'variables';
@import 'mixins/index';
@import 'css-components';

body {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;
  background: white;
  font-family: $primary-font;
  min-height: 100vh;

  font-size: 18px;
  line-height: 25px;
  color: $black;

  > main {
    flex: 1;
    max-width: 100%;
    overflow-x: hidden;
    height: auto;

    &.template-contact {
      margin-bottom: 20rem;

      .honingraad {
        img {
          transform: translateY(-60%);
        }
      }
    }

    &.archive-behandelaars{
      .sidebar{
        background: #FAF8F6;
        padding: 0 10px 10px;
      }
    }
  }

  &.mobile_menu_open {
    position: fixed;
    max-height: 100vh;
    width: 100%;
  }


}

a {
  text-decoration: none;
  color: $blue;

  &:hover {
    text-decoration: underline;
  }
}

@include headers {
  @include secondary-font();

  position: relative;
  text-transform: uppercase;

  @include text-size(50px, 5vw);

  color: $black;
}

@include headers(h2) {
  @include text-size(40px, 4vw);
}

@include headers(h3) {
  @include text-size(30px, 3vw);
}

.bg-image-container {
  position: relative;
  width: 100%;

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.top-spacing {
  margin-top: $spacing;
}

.the_content {
  position: relative;

  h1 {
    margin-bottom: 50px;
  }

  p {
    margin-bottom: 30px;
  }
}

.stretched-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.top-content {
  .the_content {
    max-width: 1050px;
    z-index: 5;
  }

  .image {
    z-index: 5;
  }

  img {
    max-height: 500px;
    width: auto;
    object-fit: cover;
  }
}

.z-1 {
  z-index: 1;
}

.faq-item {
  ul {
    list-style: disc;
    margin-left: 1em;
  }
}

.related.products {
  clear: both;
}


@import 'components';
@import 'pages';
