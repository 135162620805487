.form-container {
  position: relative;
  z-index: 1;

  .gform_heading {
    @apply hidden;
  }

  .gfield {
    .gfield_required {
      @apply text-zz-blue;
    }

    label {
      font-size: 18px;
      line-height: 22px;
      font-weight: normal;
    }

    input,textarea, select, .chosen-choices {
      outline: none !important;
      border: 2px solid;
      border-radius: 4px;
      background-color: white;

      @apply border-zz-grey;

      &:active, &:focus {
        @apply border-zz-blue;
      }
    }
  }

  [type="submit"] {
    border-radius: 4px;
    border: 2px solid;

    @apply bg-zz-blue text-white font-bold py-4 px-8 border-zz-blue transition;
    @apply hover:bg-zz-white hover:text-zz-blue;
  }

  &.on-black {
    .gfield {
      label {
        @apply text-white;
      }

      input,textarea {
        @apply border-zz-white bg-zz-black text-zz-white;
      }
    }
  }
}