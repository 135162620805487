@import '../variables';

.btn {
  display: inline-block;
  text-decoration: none!important;
  font-size: 20px;
  line-height: 28px;
  border-radius: 8px;
  @include secondary-font();
  border: 3px solid;
  text-transform: uppercase;
  padding: 5px 20px;

  @apply transition;

  &-black {
    font-size: 15px;
    line-height: 21px;

    @apply bg-zz-black text-zz-beige border-zz-black;
    @apply hover:text-zz-black hover:bg-zz-beige;
  }

  &-blue {
    font-size: 20px;
    line-height: 28px;

    @apply bg-zz-blue text-zz-white border-zz-blue;
    @apply hover:text-zz-white hover:bg-zz-blue;
  }

  &-cart {
    padding: 3px 20px;
    font-family: $primary-font;

    text-transform: none;

    font-size: 100%;

    @apply bg-zz-blue text-zz-white border-zz-blue;
    @apply hover:text-zz-white hover:bg-zz-blue;
  }

  &:disabled {
    @apply bg-zz-grey text-white border-zz-grey;
    @apply hover:text-zz-grey hover:bg-zz-grey;
  }
}