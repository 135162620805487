@import '../../variables';

.breadcrumbs {
  position: relative;
  z-index: 1;
  margin-top: 50px;
  margin-bottom: 30px;

  .breadCrumbs {
    > i {
      margin: 0 10px;
    }

    > span {
      color: $blue;

      a {
        text-decoration: none !important;
        @apply text-black hover:text-zz-blue;
      }
    }
  }
}