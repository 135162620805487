.archive_machine_item {
  width: 100%;
  height: 100%;

  .inner {
    background: white;
    height: 100%;
    @apply flex flex-col;

    .image-section {

      img {
        width: 100%;
        height: 260px;
        object-fit: cover;
      }
    }

    .text-section {
      flex:1;
      padding: 15px;
      @apply flex flex-col justify-between;

      .title {
        margin-top: 25px;
        margin-bottom: 20px;
      }

      .read-more {
        z-index: 1111;
        position: relative;
        @apply flex items-center;

        i {
          @apply transition-all;
          margin-left: 10px;
          margin-right: 10px;
        }

        &:hover {
          @apply no-underline;
          i {
            margin-left: 20px;
            margin-right: 0;
          }
        }
      }

      .excerpt {
        margin-bottom: 10px;
      }
    }
  }
}