@mixin text-size($sizes...) {
  $joined-sizes: '';
  $first: true;
  @each $size in $sizes {
    @if $first == false {
      $joined-sizes: #{$joined-sizes}, #{$size};
    } @else {
      $joined-sizes: #{$size};
      $first: false;
    }
  }

  font-size: calc(min(#{$joined-sizes}));
  line-height: 1.2;
}