@import '../variables';

.page-template-cart {
  .cart_totals {
    float: unset !important;
    width: 100% !important;
    max-width: 580px;

    h2 {
      font-size: 28px;
      line-height: 1.2;
    }
  }
}