.machine_slider {
  .splide {
    position: relative;

    .splide__arrows {
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      left: 0;
      width: 100%;

      display: flex;
      justify-content: space-between;

      button {
        pointer-events: auto;
        transform: translateX(50%);
        fill:  $blue;

        &.hide {
          pointer-events: none;
          visibility: hidden;
        }
      }

      .splide__arrow--prev {
        transform: rotate(180deg) translateX(50%);
      }
    }
  }
}