.downloads_item {
  .content {
    position: relative;
    @apply flex gap-8 flex-col md:flex-row;

    .left {
      .thumbnail {
        max-width: 130px;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    .right {
      .title {
        text-transform: none;
        font-size: 18px;
        line-height: 25px;
        margin:0;
      }

      .the_content {
        font-size: 15px;
        line-height: 25px;

        p {
          margin-bottom: 25px;
        }
      }

      .btn {
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
}