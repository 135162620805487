.template-home {
  .top-content {
    @apply -mt-24 mb-12;
  }

  .home-header {
    .image {
      .image-container {
        @apply relative;

        &:after {
          z-index: -4;
          @apply absolute bg-black;
          content: '';
          top: 50px;
          left: -40px;
          width: 100vw;
          height: 100%;
        }
      }
    }
  }
}