.archive_news_item {
  .inner {
    position: relative;
    z-index: 1;

    .thumbnail-container {
      overflow: hidden;

      img {
        transition: all 0.3s ease-in-out;
        width: 100%;
        height: 500px;
        object-fit: cover;
      }
    }

    .text-container {
      margin-top: 15px;

      .title {
        font-size: 25px;
        line-height: 1.2;
      }

      .date {
        font-size: 15px;
        line-height: 1.2;
        opacity: .5;
      }
    }

    &:hover {
      .thumbnail-container {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}