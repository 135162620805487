@import '../variables';

.page-template-checkout {
  h2 {
    font-size: 48px;
    line-height: 1.2;
    margin-top: 80px;
  }

  form {
    .form-row {
      label {
        font-size: 18px;
        line-height: 22px;
        font-weight: normal;
      }
      input,textarea {
        outline: none !important;
        border: 2px solid;
        border-radius: 4px;
        background-color: white;
        padding: 8px;

        @apply border-zz-grey;

        &:active, &:focus {
          @apply border-zz-blue;
        }
      }
    }
  }
}

.woo-form {
  form {
    .form-row {
      label {
        font-size: 18px;
        line-height: 22px;
        font-weight: normal;
      }
      input,textarea {
        outline: none !important;
        border: 2px solid;
        border-radius: 4px;
        background-color: white;
        padding: 8px;

        @apply border-zz-grey;

        &:active, &:focus {
          @apply border-zz-blue;
        }
      }
    }
  }
}