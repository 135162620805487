@import '../../variables';
.bottom_call_to_action {
  .inner {
    padding: 45px 0;
    width: 700px;
    max-width: 100%;
    margin: auto;
    .the_content {
      h1,h2,h3,h4,h5,h6 {
        color: $beige;
        margin-bottom: 20px;
      }
      form{
        input{
          background: none;
          white-space: normal;
          max-width: 95%;
        }
      }

      color: white;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }
}