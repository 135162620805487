.archive_behandeling_item {
  position: relative;
  width: 100%;

  .inner {
    position: relative;

    .image-container {
      img {
        width: 100%;
        height: 440px;
        object-fit: cover;
      }
    }

    .text-container {
      position: relative;
      margin-top: -70px;
      padding: 15px;
      background-color: #fff;
      margin-right: 25px;

      h3 {
        font-size: 25px;
        line-height: 25px;
      }
      p{
        font-size: 18px;
        line-height:22px;
        margin-bottom:10px;
      }
    }

    .stretched-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

    }
  }
}