@import '../variables';
$spacing: 10rem;

.bg-square-container {
  position: relative;

   > *:not(.square) {
     position: relative;
     z-index: 1;
   }

  .square {
    pointer-events: none;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;


    &.half {
      width: 50%;
    }

    &.third {
      width: 33.33%;
    }

    &.quarter {
      width: 25%;
    }

    &.size-4\/5 {
      width: 80%;
    }

    &.size- {
      &4\/5 {
        width: 80%;
      }

      &3\/5 {
        width: 66.66%;
      }

      &2\/5 {
        width: 50%;
      }

      &1\/5 {
        width: 33.33%;
      }

      &9\/10 {
        width: 90%;
      }

    }

    &.right {
      right: 0;
      left: unset;
    }

    &.black {
      background-color: $black;
    }

    &.beige {
      background-color: $beige;
    }

    &.spacing-right {
      width: calc(100% - #{$spacing});
    }

    &.spacing- {
      &small {
        width: calc(100% - 4rem);
      }
    }

    &.max-height {
      max-height: 400px;
    }
  }
}

@media (max-width: 900px) {
  .bg-square-container {
    .square {
      width: 100% !important;
    }
  }
}

@media(max-width: 1399px) {
  .bg-square-container {
    .square.large-content {
      width: 100% !important;
    }
  }
}