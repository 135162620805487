.archive_partners {
  .partner-container {
    .partner-item {
      .inner {
        position: relative;
        z-index: 1;
        margin-bottom: 45px;
        @apply grid grid-cols-1 md:grid-cols-12 gap-12;

        .image-column {
          display: flex;

          order: 0;
          @apply md:col-span-3 w-full justify-center;

          img {
            width: 100%;
            max-width: 230px;
            height: 320px;
            object-fit: cover;
          }
        }

        .text-column {
          order: 1;
          @apply md:col-span-9;

          .content {
            position: relative;
            margin-left: 46px;

            .quote {
              @apply text-zz-beige;
              position: absolute;
              top: 0;
              font-size: 2em;
              left: 0;
              transform: translateX(calc(-100% - 10px));
            }

            .title {
              font-size: 25px;
              line-height: 35px;
              margin-bottom: 5px;
            }

            .salon {
              display: inline-block;
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 20px;
            }
          }
        }
      }

      &:nth-of-type(even) {
        .image-column {
          justify-content: flex-end;
          order: 3;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .archive_partners {
    .partner-container {
      .partner-item {
        .inner {
          .image-column {
            justify-content: center;
            order: 0;
          }
        }
      }
    }
  }
}