.honingraad {
  pointer-events: none;
  position: absolute;
  left: 0;
  opacity: 0.5;

  img {
    transform: translate(-25%, -50%) rotate(-39deg);
  }

  &.type-2 {
    right: 0;
    left: unset;
    img {
      transform: translate(0%, -30%);
    }
  }

  &.type-3 {
    margin-top: -130px;
    right: 0;
    left: unset;

    img {
      transform: translate(0%, -30%) rotate(39deg);
      max-height: 800px;
    }
  }
  &.type-5 {
       margin-top: -130px;
       right: 0;
       left: unset;

       img {
         transform: translate(0%, -30%) rotate(0);
         max-height: 800px;
       }
     }
}