.flexible-content {
  .the_content {
    p {
      max-width: 950px;
    }

    h2 {
      font-size: 25px;
      line-height: 1.2;
    }
  }

  .content-type {
    margin-bottom: 45px;
  }

  .content-type-image {
    img {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
    }
  }

  .content-type-image_with_usps {
    margin-top: 65px;

    .content {
      max-width: 1000px;
      @apply grid lg:grid-cols-2;

      .usp-content, .text-content {
        justify-content: center;
        align-items: center;

        padding: 15px 0;

        h2 {
          font-size: 25px;
          line-height: 1.2;
          margin-bottom: 15px;
        }

        ul {
          margin: 0 0 15px 0;
          padding: 0;

          li {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 5px;
            display: flex;

            i {
              margin-right: 5px;
              @apply text-zz-blue;
            }
          }
        }
      }

      img {
        margin-top: -65px;
        margin-bottom: 65px;

        max-width: 425px;
        width: 100%;
        max-height: 425px;
        object-fit: cover;
      }

    }
  }

  .content-type-text_with_image {
    margin-top: 65px;

    .content {
      max-width: 1000px;
      @apply grid lg:grid-cols-2;

      .text-content {
        @apply flex justify-center items-center;

        padding: 15px 0;

        h1, h2, h3, h4, h5, h6 {
          font-size: 25px;
          line-height: 1.2;
          margin-bottom: 15px;
        }

        ul {
          li {
            &:before {
              content: "•";
              margin-right: 5px;
            }

            font-size: 18px;
            line-height: 22px;
            margin-bottom: 5px;
            display: flex;

            i {
              margin-right: 5px;
              @apply text-zz-blue;
            }
          }
        }
      }

      img {
        margin-top: -65px;
        margin-bottom: 65px;

        max-width: 425px;
        width: 100%;
        max-height: 425px;
        object-fit: contain;
      }

    }
  }

  .content-type-faq {
    .faq-container {
      @apply my-36;
    }

    h2 {
      @apply text-center;
        font-size: 25px;
        line-height: 1.2;
    }

    .faq-items {
      margin-top: 30px;


    }
  }
}