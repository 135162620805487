.default-page-styling {
  h1 {
    word-break: break-word;
    font-size: clamp(22px, 4vw, 60px);
    line-height: 1.2;
  }


  .top-content {
    @apply -mt-24 mb-12;
  }

  .default-top-content {
    position: relative;

    @apply grid grid-cols-2;

    .inner {
    }
  }

  .top-content-holder {
    &.single-image {
      .img-container {
        @apply relative;

        &:after {
          z-index: -4;
          @apply absolute bg-black;
          content: '';
          top: 50px;
          left: -40px;
          width: 100vw;
          height: 100%;
        }
      }
    }

    &.has-extra-image {
      min-height: 560px;

      .two-images-container {
        @apply relative lg:absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        @apply grid lg:grid-cols-2 gap-12;

        .image-holder {
          margin-right: 30px;

          @apply grid grid-cols-2 gap-8;

          img {
            height: 500px;
            width: 100%;
            object-fit: cover;

            &:first-of-type {
              margin-top: -30px;
            }
            &:nth-of-type(2) {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}