@import '../variables';

.archive-downloads {
  .sidebar {
    .filter-items {
      .filter-item {
        margin-bottom: 15px;
        position: relative;
        @apply bg-zz-beige text-black;

        .filter-item-header {
          position: relative;
          @include secondary-font();
          padding: 10px 10px;
          @apply flex justify-between items-center;
          cursor: pointer;

          i {
            transition: all .2s;
          }
        }

        .filter-item-content {
          display: none;
          position: relative;
          padding: 0.5rem 0;

          ul {
            margin-left: 15px;

            li {
              a {
                color: black;
              }
            }
          }
        }

        &[aria-expanded="true"] {
          .filter-item-content {
            display: block;
          }

          .filter-item-header {
            i {
              transform: rotate(180deg);
            }
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 50vw;
          height: 100%;
          @apply bg-zz-beige;
        }
      }
    }
  }

  .download-content {
    @apply grid lg:grid-cols-4 gap-12;

    .sidebar {
      @apply col-span-1;
    }

    .download-items {
      @apply lg:col-span-3 grid sm:grid-cols-2 gap-12;
    }
  }
}