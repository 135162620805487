@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');


$primary-font: 'Lato', sans-serif;
$secondary-font: 'Poppins', sans-serif;

@mixin secondary-font() {
  font-family: $secondary-font;
  font-weight: 900;
}